<div class="container" *ngIf="tracking != undefined && tracking != null">
  <div class="row">
    <div class="col-sm-12" id="tracking">
      <span id="trackingSpan">
        <div class="block">
          <h1>TRACK & TRACE</h1>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="table-responsive-sm scrollable-element">
              <table id="primeraTabla"
                class="table table-sm table-bordered table-hover table-editable table-row-action edit-action">
                <thead>
                  <th>{{'Ref. Cliente'|translate}}</th>
                  <th>{{'Remitente'|translate}}</th>
                  <th>{{'Destinatario'|translate}}</th>
                  <th>{{'Bultos'|translate}}</th>
                  <th>{{'P.Bruto'|translate}}</th>
                  <th>{{'Volumen'|translate}}</th>
                  <th>{{'Estado'|translate}}</th>
                </thead>
                <tbody>
                  <tr>
                    <td>{{tracking.ReferenciaCliente}}</td>
                    <td>{{tracking.Remitente}}</td>
                    <td>{{tracking.Destinatario}}</td>
                    <td>{{tracking.Bultos}}</td>
                    <td>{{tracking.PesoBruto}}</td>
                    <td>{{tracking.Volumen}}</td>
                    <td>{{tracking.Status | translate}}</td>
                  </tr>
                </tbody>
              </table>
              <table class="table table-sm table-bordered table-hover table-editable table-row-action edit-action">
                <thead>
                  <th *ngIf="isPartida()">{{'Partida'|translate}}</th>
                  <th *ngIf="isExpedicion()">{{'Expedicion'|translate}}</th>
                  <th *ngIf="isPartida()">Ref/AWB/MBL</th>
                  <th>{{'Origen'|translate}}</th>
                  <th>{{'Destino'|translate}}</th>
                  <th>ETD</th>
                  <th>ETA</th>
                  <th *ngIf="!isPartida()">{{'Transportista'|translate}}</th>
                  <th *ngIf="isPartida() && isImport()">{{'Agente en origen'|translate}}</th>
                  <th *ngIf="isPartida() && !isImport()">{{'Agente de destino'|translate}}</th>
                  <!-- <th *ngIf="tieneIncidencia()">{{'Incidencia'|translate}}</th> -->
                </thead>
                <tbody>
                  <td *ngIf="isPartida()">{{tracking.Partida}}</td>
                  <td *ngIf="isExpedicion()">{{tracking.Expedicion}}</td>
                  <td *ngIf="isPartida()">{{tracking.RefAwbMbl}}</td>
                  <td>{{tracking.Origen}}</td>
                  <td>{{tracking.Destino}}</td>
                  <td>{{tracking.ETD}}</td>
                  <td>{{tracking.ETA}}</td>
                  <td *ngIf="!isPartida()">{{tracking.Carrier}}</td>
                  <td *ngIf="isPartida()">{{tracking.DeliveryAgent}}</td>
                  <!-- <td *ngIf="tieneIncidencia()" [ngClass]="{'fondoRojo': tieneIncidencia()}"></td> -->
                </tbody>
              </table>
              <!-- <mat-horizontal-stepper [linear]="true" #stepper>
                              <mat-step *ngFor="let linea of getLocations()" [stat="boat" [completed]="linea.Concluido">
                                  <ng-template matStepLabel>{{linea.Location}}</ng-template>
                              </mat-step>
                              
                              <!-- <mat-step state="state" [completed]="true" [editable]="false">
                              </mat-step>
                              <mat-step state="state2" [completed]="false" [editable]="false">
                              </mat-step>
                              <mat-step state="state3" [completed]="false" [editable]="false">
                              </mat-step>
                          </mat-horizontal-stepper> -->
              <table class="table table-sm table-bordered table-hover table-editable table-row-action edit-action">
                <thead>
                  <th>{{'LUGAR'|translate}}</th>
                  <th>{{'ESTIMADO'|translate}}</th>
                  <th>{{'FECHA'|translate}}</th>
                  <th>{{'ESTADO'|translate}}</th>
                  <th *ngIf="tracking.Area != 2">{{'DETALLES'|translate}}</th>
                  <th *ngIf="tracking.Area == 2">{{'VESSEL'|translate}}</th>
                  <!-- <th>{{'INCIDENCIA'|translate}}</th> -->
                  <th></th>
                  <!-- <th>{{'CONCLUIDO'|translate}}</th> -->
                  <!-- <th>{{'DIAS RETRASO'|translate}}</th> -->
                </thead>
                <tbody *ngIf="isUndefined()">
                  <tr *ngFor="let linea of tracking.LineasTracking">
                    <td>{{linea.Location}}</td>
                    <td [ngClass]="{'fondoRojo': isViajeRetrasado(linea), 'fondoVerde': isViajeAdelantado(linea)}">
                      {{linea.DateETA}}</td>
                    <td [ngClass]="{'fondoRojo': isViajeRetrasado(linea), 'fondoVerde': isViajeAdelantado(linea)}">
                      {{linea.Date}}</td>
                    <td>{{linea.Status | translate}}</td>
                    <td>{{linea.Details}}</td>
                    <!-- <td>{{linea.Incidencia}}</td> -->
                    <td *ngIf="linea.Concluido"><mat-icon class="green-icon">check_circle_outline</mat-icon></td>
                    <td *ngIf="!linea.Concluido"><mat-icon>minimize</mat-icon></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </span>
      <iframe *ngIf="tracking.Url != undefined && tracking.Url != null" [src]="tracking.UrlSanitizer"
        id="IframeShipsgoLiveMap" style="height: 550px;width: 100%;"></iframe>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-2">
      <!-- <button type="button" class="btn" style="margin: 5px; height: 36px;"
              (click)="showURL = !showURL">
              <mat-icon>share</mat-icon>
          </button> -->
    </div>
    <div class="col-sm-6"></div>
    <div class="col-sm-2"></div>
    <div class="col-sm-2" style="text-align: right;">
      <button type="button" class="btn" style="margin: 5px;"
        (click)="downloadPDF()">{{'ImprimirTracking'|translate}}</button>
    </div>
  </div>
  <!-- <div class="row">
      <div class="col-sm-6" *ngIf="showURL" style="align-items:flex-end;">
          <app-share-tracking [partida]="tracking.Partida" [expedicion]="tracking.Expedicion"></app-share-tracking>
      </div>
      <div class="col-sm-6"></div>
  </div> -->
</div>